import React from "react"
import { Link } from "gatsby"

import Page from "../templates"
import SEO from "../templates/seo"

const NotFoundPage = () => (
  <Page>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p className="mb-5">Diese Seite existiert leider nicht.</p>
    <p className="mb-5">
      <Link to="/">zurück zur Startseite</Link>
    </p>
  </Page>
)

export default NotFoundPage
